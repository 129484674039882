.bg-home {
    background-size: cover;
    float: left;
    width: 100%;
    margin-top: -10px;
    padding: 30px 0 0;
}
body:has(.game-box-left),
body:has(.box-bg-game) {
    background: url(../../image/common/bg.jpg) center top 80px no-repeat #000;
    background-size: cover;
    float: left;
    width: 100%;
}
.swiper-slide img {
    width: 100%;
}
.dat-cuoc {
    border-radius: 10px;
    background: #fef8a1;
    color: #f7f6f6;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    margin: 10px;
    text-transform: uppercase;
    font-weight: bold;
}
.box-text {
    background: linear-gradient(180deg, #ff598e, #ff2d55);
    border-radius: 10px;
    color: #fefafa;
    font-size: 18px;
    padding: 7px 15px;
    text-align: left;
    margin-bottom: 15px;
    margin: 10px;
    position: relative;
    width: 100%;
}
.list-game-xs,
.list-game {
    display: flex;
    align-items: start;
    flex-flow: row wrap;
}
.list-game-xs {
    justify-content: center;
    margin-bottom: 30px;
}
.list-game-xs > * {
    padding: 0 15px;
}
.flex-home {
    display: flex;
    align-items: end;
    justify-content: space-between;
    padding-top: 20px;
}
.banner-left img {
    transition: all 0.2s ease;
}
.banner-left img:hover {
    transform: translateY(-20px);
    transition: all 0.2s ease;
}
.entertaiment {
    position: relative;
    text-align: center;
    margin-top: 100px;
}
.home-popular__sub {
    background: linear-gradient(180deg, rgba(160, 172, 201, 0.12), rgba(160, 172, 201, 0));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    font-size: 56px;
    position: absolute;
    top: -34px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: bold;
    width: 1000px;
}
.home-popular__title {
    text-transform: uppercase;
    font-size: 24px;
    color: #fff;
    font-weight: bold;
}
.home-popular__game {
    max-width: calc(50% - 10px);
    background: hsla(0, 0%, 100%, 0.1);
    border: 1px solid hsla(0, 0%, 100%, 0.3);
    box-shadow: 0 6px 12px rgba(46, 192, 87, 0.1), inset 0 2px 6px hsla(0, 0%, 100%, 0.2);
    border-radius: 16px;
    align-items: center;
    justify-content: flex-end;
    flex: 1 1 0%;
    height: 290px;
    display: flex;
    position: relative;
    background-image: url(../../image/bg-green.png);
    background-position: left top;
    background-size: contain;
}
.home-popular__game .rebate__wrap {
    display: flex;
    justify-content: center;
}
.home-popular__game img {
    height: 320px;
    position: absolute;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.home-popular__game .image {
    height: 320px;
    width: 65%;
    position: absolute;
    left: 0;
    bottom: 0;
}
.home-popular__game:hover img {
    transform: translateX(20px);
}
.header-rebate-tag {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 4px;
    font-size: 12px;
    color: #fff;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJwAAAAqCAMAAABx9cIXAAAAe1BMVEUAAAD/hzj/iDT/gDD/uYf/iTP/iDP/hzT/ijP/iTP/u4n/ijP/iTP/sHf/ijT/iDT/uon/sXn/hzL/vIn/u4v/s4D/ijX/t4f/iTT/u4n/nFX/kkT/sXn/qm3/pWX/l0z/n1v/toH/uYf/ijb/rnP/ol//jz7/jDr/kD7pyA7oAAAAGHRSTlMAIIAQ39+QQO+gf29fz8+/r6BgUEBAMCDQx5zXAAAA5UlEQVRYw+3WR27DQBBE0bLMIFlyTjXkMCff/4QmoYW9tiHgL/RO8DEDdLfOXp+S8B99uARt3pKApNVdCLH9Mk0I57bWQFvcZ98XJup7KemQ72Z3nd67aKY17jkyP9WOUfexNFOMGsfBTOOoqjJUVbHj6tpQdc2OmyZDTZNa6H6w21YFdQa7KNhxTWOopmHHldTV6rJkx82zoeZZy2KoZdFAvZg8DDLXAznuQI47geNSgeNO4LhM3LiDsHH7o6hx6e5Wv+Mes+NLnn/cEGxlP3H7tRTJdgZNk+ydsMhtSgWW6+pPvgFo0vNj2KI8LwAAAABJRU5ErkJggg==)
        50% / 100% 100% no-repeat;
    margin: auto;
}
.header-rebate-tag .rebate-num {
    margin-left: 2px;
    font-size: 14px;
}
.relative {
    position: relative;
}
.home-popular__game .text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: 45%;
    padding: 10px 20px;
    height: 290px;
    position: absolute;
    right: 0;
    top: 0;
}
.home-popular__game .text h3 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    color: #fff;
}
.home-popular__game .text p {
    color: #fff;
    font-weight: 500;
    margin-bottom: 20px;
}
.home-popular__game .text button {
    padding: 10px 30px;
    width: auto;
    font-size: 14px;
}
.home-popular__game-third {
    background: url(../../image/game-bg.png) 50% no-repeat;
    background-size: contain;
    max-width: 220px;
    padding: 0 10px;
    text-align: center;
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
    transition-duration: 0.3s;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter,
        -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
    background-position: bottom;
}
.home-popular__game-third h3 {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 10px 0;
}
.home-popular__game-third button {
    margin-bottom: 20px;
    width: 90%;
    font-size: 14px;
    padding: 5px 0;
}