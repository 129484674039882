.bgregister {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../image/common/bg-games.jpg);
    background-size: cover;
    padding: 50px 0;
    min-height: 68vh;
}
.h1login {
    color: #fff;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
}
.formlogin {
    border-radius: 10px;
    max-width: 300px;
    margin: 30px auto;
    text-align: left;
    text-align: center;
}
.formlogin .form_group {
    margin-bottom: 15px;
    position: relative;
}
.formlogin .form_group span {
    font-size: 18px;
    font-weight: 500;
    display: block;
    margin-bottom: 15px;
    color: #000;
}
.formlogin .form_group input {
    font-size: 16px;
    color: #fff;
    border: 1px solid transparent;
    background: hsla(0, 0%, 93%, 0.1);
    width: 100%;
    padding: 0;
    line-height: 2;
    outline: none;
    padding: 5px 15px;
    border-radius: 5px;
}
.formlogin .form_group input:focus {
    border-color: #219750;
}
.formlogin .form_group svg {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    color: #ccc;
}
.formlogin p {
    color: #ffa2a2;
    font-size: 15px;
    display: inline-block;
    margin: 5px 0;
    position: relative;
}
.formlogin .regisnow {
    font-size: 16px;
    text-align: right;
}
.formlogin .regisnow a {
    color: #f0c882;
}
.formlogin .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}
.formlogin .row div {
    display: flex;
    align-items: center;
    font-size: 14px;
}
.formlogin .row img {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    margin-right: 10px;
}
.btngold {
    background: #f0c882;
    border: 0;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    padding: 15px;
    border: 0;
    border-radius: 10px;
    width: 100%;
    margin: 30px 0;
}
.login_text {
    margin-top: -10px;
    margin-bottom: 30px;
}
.boxregister {
    background-color: #222428;
    background-image: url(../../image/box-pic.png);
    background-position: right -20px;
    background-repeat: no-repeat;
    background-size: 45%;
    border: 0.5px solid rgba(225, 225, 255, 0.2);
    width: 692px;
    border-radius: 8px;
    margin: auto;
    padding: 20px 0 0;
}
