.banner-game {
    width: 100%;
    position: relative;
}
.banner-game img {
    width: 100%;
}
.menu-box-game {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 30px;
    margin-bottom: 30px;
}
.menu-box-game-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
}
.menu-box-game-5 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
}
.grid-5 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
    margin-top: 10px;
}
.grid-6 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
    margin: 0 0 30px;
    height: 600px;
    max-height: 600px;
    overflow-y: auto;
}
.pointer {
    cursor: pointer;
}
.box-game {
    margin: 15px;
    position: relative;
    overflow: hidden;
}
.box-game:after {
    display: none;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9;
    transition: all 0.5s;
}
.box-game:hover:after {
    display: block;
}
.box-game-image img {
    vertical-align: bottom;
    width: 100%;
}
.box-game-play {
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all 0.3s;
    z-index: 10;
}
.box-game-play span {
    width: 100px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border: 0 solid #000;
    color: #333;
    background: #fef8a1;
    cursor: pointer;
}
.box-game:hover .box-game-play {
    opacity: 1;
}
.sub_logo {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 120px;
}
.view-more {
    font-size: 16px;
    color: #ff0000;
    border: 2px solid #ff0000;
    border-radius: 50px;
    padding: 5px 15px;
    width: 200px;
    margin: 0 auto;
    text-transform: uppercase;
    font-weight: bold;
}
.relative-input {
    position: relative;
}
.absolute-input {
    position: absolute;
    top: 0.1rem;
    right: calc(5% + 0.1rem);
    border-radius: 0.26667rem;
    background: #fce406;
    height: 0.97333rem;
    line-height: 0.97333rem;
    padding: 0 0.3rem;
    cursor: pointer;
}
.gameapi-list {
    display: flex;
    flex-flow: row wrap;
}
.gameapi-item {
    width: calc(100% / 3);
    padding: 10px;
    position: relative;
}
.gameapi-item img {
    float: left;
    width: 100%;
    box-shadow: 0 0.02rem 0.06rem rgba(0, 0, 0, 0.06);
    background: #fff;
    border-radius: 10px;
}
.gameapi-name {
    background: linear-gradient(180deg, rgb(37 66 144 / 80%), rgb(37 66 144 / 90%));
    color: #fff;
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: calc(100% - 20px);
    font-size: 13px;
    text-align: center;
    padding: 10px 2px;
    border-radius: 0 0 10px 10px;
}
.bg-games-pro {
    position: relative;
    min-height: calc(100vh - 185px);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.bg-games-pro .image-ab {
    position: absolute;
    bottom: 0;
    left: 40%;
    transform: translateX(150%);
    transition: all 0.4s;
}
.bg-games-pro .image-ab.active {
    transform: translateX(0);
    transition: all 0.4s;
}
.bg-games-pro-text {
    position: absolute;
    left: 0;
    top: 100px;
    width: 100%;
    z-index: 2;
}
.bg-games-pro-text h2 {
    font-size: 45px;
    background: -webkit-linear-gradient(#1b74f0, #61c7fd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    margin-bottom: 20px;
    filter: drop-shadow(#bed7ef99 0 3px 6px);
}
.bg-games-pro-text .tab-navigation {
    justify-content: start;
}
.bg-games-pro-text .tab-navigation > li {
    width: 100px;
    height: 100px;
    aspect-ratio: 1;
    line-height: 100px;
    flex: 0 0 100px;
    padding: 0;
    border-radius: 100%;
    background: url(../../images/venue_bj.png);
    background-size: 100% 100%;
    color: #fff;
    filter: drop-shadow(#bed7ef99 0 3px 6px);
}
.bg-games-pro-text .tab-navigation > li.active {
    background-image: url(../../images/venue_active.png);
}
.bg-games-pro-text button {
    background-image: url(../../images/blue_btn.png.png);
    background-size: 100% 100%;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    border: 0;
    padding: 15px 40px;
    margin-top: 20px;
}
.tabframe {
    position: relative;
    display: flex;
    height: 100px;
    border-radius: 10px;
    background: url(../../image/swiper_bg.png) no-repeat 50%/110%;
    margin: 0 0 50px 0;
    overflow: hidden;
    padding: 0 20px;
    justify-content: center;
}
.tabframeitem {
    padding: 10px 0;
    margin: 0 10px;
    height: 100px;
    color: hsla(0,0%,100%,.6);
    text-transform: uppercase;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    cursor: pointer;
    border-bottom: 5px solid transparent;
    width: 100%;
    max-width: 200px;
}
.tabframeitem img {
    height: 40px;
    width: auto;
    filter: grayscale(1);
}
.tabframeitem.active {
    border-bottom: 3px solid #219750;
    color: #219750;
}
.tabframeitem.active img {
    filter: none;
}
.tabframeitem p {
    font-size: 16px;
}
.play-btn {
    background: linear-gradient(180deg, #219750, #1f8e29);
    color: #fff;
    border-radius: 5px;
    border: 0;
    font-size: 16px;
    padding: 12px 60px;
    font-weight: 500;
    cursor: pointer;
    display: inline-block;
}
.play-btn:hover,
.play-btn:focus {
    transition: all 0.3s;
    transform: scale(1.1);
}
.tab_item {
    position: relative;
    text-align: center;
    overflow: hidden;
    height: 550px;
}
.tabitem {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    height: 550px;
    padding-top: 30px;
}
.tabitem.active {
    z-index: 1;
}
.tabitem .content-box {
    position: absolute;
    text-align: left;
    top: 100px;
    left: 0;
    width: 50%;
    transform: translateX(-100%);
    transition: all 0.5s;
}
.content-box .icon-pic {
    margin: 15px 0 30px 0;
}
.tabitem .pic-box {
    width: 50%;
    margin-left: auto;
    transform: translateX(100%);
    transition: all 0.5s;
}
.tab_item:before {
    position: absolute;
    bottom: 0;
    height: 100%;
    content: "";
    width: 350px;
    -webkit-transform: skewX(40deg);
    transform: skewX(40deg);
    right: 300px;
    background: linear-gradient(.644turn, #219750 -39.61%, rgba(33, 151, 80, 0) 118.8%), url(../../image/dot.png) no-repeat 0 0 / contain;
}
.tabitem.active .pic-box,
.tabitem.active .content-box {
    transform: translateX(0);
    transition: all 0.5s;
}
.box-fishing {
    height: 600px;
    position: relative;
    margin-top: 50px;
    z-index: 1;
}
.box-fishing img {
    height: 250px;
    filter: drop-shadow(2px 4px 6px black);
    transition: linear 0.35s;
    animation: game-float 2s 0.2s cubic-bezier(0.4, 0, 1, 1) infinite;
    cursor: pointer;
}
.box-fishing .fishing-play:first-child {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}
.box-fishing .fishing-play:first-child img {
    animation-delay: 0.8s;
}
.box-fishing .fishing-play:nth-child(2) {
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translateY(-50%);
}
.box-fishing .fishing-play:nth-child(4) img {
    animation-delay: 1.2s;
}
.box-fishing .fishing-play:nth-child(3) {
    position: absolute;
    top: 50%;
    right: 60%;
    transform: translateY(-50%);
}
.box-fishing .fishing-play:nth-child(3) img {
    animation-delay: 0.5s;
}
.box-fishing .fishing-play:nth-child(4) {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}
@keyframes game-float {
    0%,
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    40% {
        -webkit-transform: translate3d(0, -2%, 0);
        transform: translate3d(0, -2%, 0);
    }
}
@-webkit-keyframes game-float {
    0%,
    100% {
        -webkit-transform: translate3d(0, 0, 0);
    }
    40% {
        -webkit-transform: translate3d(0, -2%, 0);
    }
}
@-webkit-keyframes game-float-l {
    0%,
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    40% {
        -webkit-transform: translate3d(0, -5px, 0);
        transform: translate3d(0, -5px, 0);
    }
}
@keyframes game-float-l {
    0%,
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    40% {
        -webkit-transform: translate3d(0, -5px, 0);
        transform: translate3d(0, -5px, 0);
    }
}
#bubble {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
}
.gameslot-item {
    position: relative;
    padding: 6px 0;
    width: 100%;
    border-radius: 8px;
    background: url(../../image/box.png) no-repeat 50%/contain;
    background-size: 100% 100%;
    color: #fff;
    text-align: center;
    cursor: pointer;
}
.gameslot-item img {
    height: 152px;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.gameslot-name {
    padding: 0 5px;
    width: 100%;
    height: 40px;
    color: #fff;
    white-space: pre-wrap;
    white-space: break-spaces;
    letter-spacing: -0.2px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}
.third-container__title {
    text-shadow: 4px -1px 2px #219750;
    line-height: 108px;
    font-size: 72px;
    color: #fff;
    font-weight: 500;
    white-space: nowrap;
    position: relative;
}
.third-container__title:before {
    position: absolute;
    bottom: 0;
    left: 0;
    font-weight: 500;
    color: #a0acc9;
    font-size: 128px;
    text-shadow: none;
    opacity: .1;
    line-height: 191.36px;
    content: "HOT"
}
.third-container__title.live:before {
    content: "CASINO";
}
.third-container__title.lottery:before {
    content: "LOTTERY";
}
.third-container__title.pvp:before {
    content: "POKER";
}
.third-container__title.esport:before {
    content: "ESPORT";
}
.third-container__title.cockfighting:before {
    content: "COCKFIGHTING";
}
.third-container__title.slot:before {
    content: "SLOT";
}
.third-container__title.sport:before {
    content: "SPORT";
}
.third-container__title.fishing:before {
    content: "FISHING";
}
.third-container__p {
    color: hsla(0, 0%, 100%, .6);
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 50px;
}