.withdraw {
    position: absolute;
    width: 100%;
    height: 100%;
}
.withdraw-header {
    height: 47px;
    line-height: 46px;
    border-bottom: 1px solid #efefef;
    background-color: #fff;
    padding-left: 30px;
    font-size: 0;
    display: flex;
    white-space: nowrap;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    z-index: 2;
}
.menu-item-withdraw {
    cursor: pointer;
    color: #666;
    font-size: 14px;
    height: 46px;
    line-height: 44px;
    display: inline-block;
    margin-right: 20px;
    padding: 0 10px;
}
.menu-item-withdraw.active {
    color: #fd2f2f;
    border-bottom: 3px solid #fd2f2f;
}
.history-title {
    line-height: 15px;
    margin: 18px 20px 0;
    padding-left: 10px;
    border-left: 4px solid #4c8bff;
    font-weight: 400;
    color: #333;
}
.withdraw-grid {
    display: grid;
    grid-template-columns: 3fr 1fr;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    padding-top: 47px;
}
.widthdraw-history {
    background: #f5f5f5;
    height: 100%;
    overflow-y: auto;
}
.withdraw form {
    margin: 0 40px;
}
.withdraw form label {
    color: #555;
    font-size: 14px;
    margin-right: 8px;
    width: 120px;
    display: inline-block;
}
.withdraw form input {
    display: inline-block;
    vertical-align: middle;
    height: 34px;
    border-radius: 5px;
    width: 223px;
    border: 1px solid #e5e5e5!important;
    background-color: #f5f5f5!important;
    color: #646464;
    outline: 0;
    padding-left: 12px;
    box-shadow: 0 1px 5px rgba(0,0,0,.08) inset;
    font-size: 14px;
}
.withdraw-grid-content {
    padding: 20px;
}
.withdraw form .input {
    margin-bottom: 15px;
}
.noBackard {
    width: 288px;
    text-align: center;
    color: #333;
}
.noBackard .bg {
    width: 288px;
    height: 175px;
    background: url(../../../image/no-bank-big.512e5cae.png) no-repeat;
    background-size: 100% 100%;
}
.noBackard p {
    margin: -10px 0 10px 0;
}
.withdraw form input:focus {
    border-color: #a0dbff!important;
}
.box-banking {
    background: url(../../../image/box-banking.png) no-repeat 50%;
    width: 350px;
    background-size: 100% 100%;
    font-size: 18px;
    margin-top: 15px;
    min-height: 160px;
    padding: 20px;
    position: relative;
    text-align: center;
    margin-left: 30px;
}
.box-banking .money_banking {
    left: 30px;
    position: absolute;
    text-align: left;
    top: 20px;
}
.box-banking .money_banking h3 {
    color: #f5f5f5;
    font-size: 16px;
    font-weight: 400;
}
.box-banking .money_banking h4 {
    color: #fff;
    font-size: 24px;
    font-weight: 500;
}
.box-banking .icon_credit {
    bottom: 30px;
    left: 30px;
    max-width: 30px;
    position: absolute;
}
.box-banking .ctk {
    bottom: 40px;
    color: #f2f2f2;
    font-size: 20px;
    position: absolute;
    right: 30px;
}
.js-withdraw-history .item-inner {
    border-bottom: 1px solid #eee;
    color: #666;
    padding: 10px 20px;
    margin-top: 10px;
}
.js-withdraw-history .item-inner .time_history {
    color: #aaa;
    font-size: 12px;
    margin-top: 5px;
}
.js-withdraw-history .text_status {
    transform: scale(0.7);
    margin: 0 0 0 -5px;
}